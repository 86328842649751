import Cookie from 'js-cookie';

import { fetchCityOwner, fetchGeocodingBoundaries, fetchGeocodingLocations, fetchSearch } from '../../../api/search';
import { favoriteProperty, postSaveSearch, unfavoriteProperty } from '../../../api/user';

export default {
    /**
     * Initialize search parameters.  Most likely passed
     * from page load
     **/
    initializeSearchLocation({ commit }, searchParams) {
        searchParams.location['bounds'] = searchParams.bounds ?? null;
        return new Promise((resolve, reject) => {
            console.log(searchParams);
            commit('setSearchLocation', searchParams.location);
            commit('setSearchAISession', searchParams.aiSessionId);
            try {
                const filter = searchParams.filter;

                if (filter && typeof filter === 'string' && /^{\s*.*\s*}$/.test(filter)) {
                    commit('setSearchFilters', JSON.parse(filter));
                }
            } catch (error) {
                console.error(error);
            }

            commit('setSearchPage', searchParams.page);
            resolve();
        });
    },
    initializeSearchBounds({ commit }, boundingCoordinates) {
        return new Promise((resolve, reject) => {
            commit('setSearchBoundingCoordinates', boundingCoordinates);
            resolve();
        });
    },

    /**
     * Marshal search requests & debounce if necesary
     **/
    search({ commit, state, dispatch }, debounceTimeLimit) {
        // define the dbounceTimeLimit or set to default
        debounceTimeLimit = debounceTimeLimit ? debounceTimeLimit : state.debounceTimeLimitDefault;

        // cancel any requests that are currently processing
        if (state.activeRequest) {
            state.activeRequest.cancel();
        }

        // activate a setTimeout to perform the search query
        return new Promise((resolve, reject) => {
            let t = setTimeout(() => {
                dispatch('performSearch')
                    .then(() => {
                        resolve();
                    })
                    .catch((e) => {
                        reject(e);
                    });
            }, debounceTimeLimit);
            commit('setDebounceTimer', t);
        });
    },

    async geoCodingBoundary({ state, commit }) {
        console.log('geoCodingBoundary', state.location);
        var boundaryQuery = state.location.value.split(',')[0];
        // if the location query type is a neighborhood (which is neighborhood or subdivision on the backend)
        // then we need to query the location bid (boundary id) from RED instead of using the location name.

        if (state.location.type.toLowerCase() === 'subdivision' || state.location.type.toLowerCase() === 'community' || state.location.type.toLowerCase() === 'neighborhood') {
            boundaryQuery = state.location.value.split(',')[0] + ', ' + state.location.city;
        }

        if (state.location.type.toLowerCase() === 'school' && state.location.id) {
            const locationId = state.location.id.split(':')[1];
            boundaryQuery = locationId;
        }

        const geoRequest = await fetchGeocodingBoundaries(boundaryQuery, state.location.type, state.location.state);
        //if (geoRequest.boundingBox && (state.location.type.toLowerCase() === 'neighborhood' || state.location.type.toLowerCase() === 'subdivision')) {
        if (geoRequest.boundingBox) {
            commit('setSearchBoundingCoordinates', geoRequest.boundingBox);
        }
        if (geoRequest.data) {
            commit('setSearchMap', {
                boundary: geoRequest.data,
                latLng: null
            });
            commit('setSearchLocation', {
                bounds: geoRequest.data,
                lat: geoRequest.lat,
                lng: geoRequest.lng
            });
            return geoRequest;
        } else {
            const locationReq = await fetchGeocodingLocations(state.location.query);

            let result = locationReq.data.results;

            let lat = result['0'].geometry.location.lat;
            let lng = result['0'].geometry.location.lng;
            commit('setSearchMap', {
                latLng: [lng, lat],
                boundary: null
            });

            return locationReq;
        }
    },

    async getCityOwner({ state, commit, dispatch }) {
        let onlybroker = null;
        if (typeof state.location.onlybroker !== 'undefined' && state.location.onlybroker) {
            onlybroker = 'BrokerOnly';
        }
        let type = state.location.type;

        let cleanCity = state.location?.city?.replace(/[^\w\s]/gi, '');
        let secondaryLocation = state.location.value.split(',')[0];
        //
        const parametersQuery = {
            stateOrProvince: state.location.state,
            type: type ?? null,
            city: cleanCity,
            ...(secondaryLocation && { secondaryLocation: secondaryLocation })
        };
        const propertyObj = {
            c_city: parametersQuery.city,
            c_state: parametersQuery.stateOrProvince
        };
        const result = await fetchCityOwner(brandPrependUrl, globalBrandGroupId, parametersQuery);

        try {
            let agentOwner = onlybroker && !result.rentalFulfillment ? result.cityRealtyBroker : result.cityAgentOwner ? result.cityAgentOwner : result.cityRealtyBroker;
            if (this.state.tracking?.propertyParams?.c_city !== propertyObj?.c_city || this.state.tracking?.propertyParams?.c_state !== propertyObj?.c_state) {
                commit('tracking/setPropertyParams', propertyObj, { root: true });
            }
            commit('setSearchAgent', agentOwner);
            commit('setStateDisclaimer', result.stateBroker);
            // ! NEED TO WORK ON THIS
            commit('setSearchLender', result.cityLenderOwner);
            commit('tracking/setLocationData', result.location, { root: true });
            // ! NEED TO WORK ON THIS
            commit('setSearchLocationInfo', result.locationInfo);
            commit('setActiveRequest', null);

            //set branding stuff for integrations
            if ((state.agent && state.agent.brandInfo) || (state.lender && state.lender.brandInfo)) {
                // let agentGroupId = state.agent?.brandInfo?.team_id ?? state.agent?.brandInfo?.branding_group_id;
                // let lenderGroupId = state.lender?.brandInfo?.team_id ?? state.lender?.brandInfo?.branding_group_id;
                let agentGroupId = state.agent?.brandInfo?.branding_group_id;
                let lenderGroupId = state.lender?.brandInfo?.branding_group_id;
                let brandGroupId = globalBrandGroupId ?? agentGroupId ?? lenderGroupId;
                let brandId = state.agent?.brandInfo?.branding_id ?? state.lender?.brandInfo?.branding_id;

                let brandInfo = {};
                brandInfo.groupLocationId = state.locationInfo;
                brandInfo.location = result.location;
                brandInfo.brand = state.agent?.brandInfo ?? state.lender?.brandInfo;
                brandInfo.timeStamp = Date.now();
                brandInfo.regConfig = result.cityAgentOwner?.optimizeConfig ?? result.cityLenderOwner?.optimizeConfig;
                if (typeof state.lender !== 'undefined' && typeof state.lender?.brandInfo !== 'undefined' && state.agent?.brandInfo?.role === 'broker' && state.lender?.brandInfo?.role === 'lender') {
                    brandGroupId = state.lender.brandInfo.branding_group_id;
                }
                commit('tracking/setBrandingGroupId', brandGroupId, { root: true });
                commit('tracking/setBrandingId', brandId, { root: true });
                commit('tracking/setEntranceData', brandInfo, { root: true });
                commit('tracking/setCurrentData', brandInfo, { root: true });
                commit('tracking/setIsProgressive', result.progressiveReg, { root: true });
                commit('tracking/setIsExtendedReg', result.extendedReg, { root: true });
                commit('tracking/setIsRentalFulfillment', result.rentalFulfillment, { root: true });
                if (this.state.tracking?.entranceData.location.city.lat && this.state.tracking?.currentData.location.city.lat) {
                    const calculateDistance = (lat1, lon1, lat2, lon2) => {
                        const p = 0.017453292519943295; // Math.PI / 180
                        const c = Math.cos;
                        const a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
                        return 7917.509282 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
                    };
                    const distance = calculateDistance(
                        Number(this.state.tracking.currentData.location.city.lat),
                        Number(this.state.tracking.currentData.location.city.lng),
                        Number(this.state.tracking.entranceData.location.city.lat),
                        Number(this.state.tracking.entranceData.location.city.lng)
                    );
                    commit('tracking/setLocationDistance', distance, { root: true });
                    brandInfo.distance = distance;
                    dispatch('tracking/setCurrentData', brandInfo, { root: true });
                }
            }
            if (state.locationInfo) {
                dispatch('tracking/setBrandingLocationId', state.locationInfo, { root: true });
                dispatch('tracking/setInitialLocationId', state.locationInfo, { root: true });
            }
        } catch {
            // counter error
        } finally {
            if (this.state.tracking?.currentData?.brand?.branding_group_id && !this.state.user.user) {
                Cookie.set('current_cookie', { brand: this.state.tracking.currentData.brand }, { path: '/' });
            }
        }
    },

    performSearch({ commit, state, dispatch }) {
        // store a new active request
        commit('setActiveRequest', axios.CancelToken.source());

        // set searchLoading parameter to true
        commit('setSearchLoading', true);

        // Update the URI with the current search filters
        // dispatch("updateSearchUriFilters");

        let fields =
            'virtualTourURLUnbranded,daysOnMarket,primaryListingImageUrl,propertyType,listingId,photosCount,unstructuredAddress,city,stateOrProvince,postalCode,standardStatus,customStatus,listPrice,livingArea,bedroomsTotal,url,bathroomsTotal,courtesyOf,listingKey,listAor,latitude,longitude,openHouseStartTimestamp,openHouseStopTimestamp,publicRemarks';

        //let fields = 'listingId,listPrice,url,listingKey,listAor,latitude,longitude';

        // prepare the query parameters by mapping
        // existing store data to the search params
        let parameters = {
            searchQuery: {
                AssociationFee: state.filters.maxHoa,
                CumulativeDaysOnMarket: state.filters.daysOnMarket,
                extended_criteria: state.filters.extendedCriteria,
                page_uri: window.location.pathname,
                IncludeClustersOrPins: true,
                listing_types: state.filters.listingTypes,
                locations: [
                    [
                        {
                            name: state.location.query,
                            type: state.location.type.toLowerCase().replace(/-/g, ' ').replace('zip', 'zip code'),
                            value: state.location.query,
                            bounds: state.location.bounds
                        }
                    ]
                ],
                MaxAcreage: state.filters.maxLotSize,
                MaxPrice: state.filters.maxPrice,
                MaxSquareFootage: state.filters.maxSqf,
                MaxYearBuilt: state.filters.maxYearBuilt,
                MinAcreage: state.filters.minLotSize,
                MinBaths: state.filters.minBathrooms,
                MinBedrooms: state.filters.minBedrooms,
                MinPrice: state.filters.minPrice,
                MinSquareFootage: state.filters.minSqf,
                MinYearBuilt: state.filters.minYearBuilt,
                OpenHouseNextXDays: state.filters.openHouseNextXDays,
                HasMatterport: state.filters.hasMatterport,
                property_types: state.filters.propertyTypes,
                property_status: state.filters.propertyStatus,
                PublicRemarks: state.filters.publicRemarks,
                sort_key: state.filters.sortBy,
                fetchLdJson: false
            }
        };
        if (!state.boundaryEnabled) {
            delete parameters.searchQuery.locations; //need new way to search ne,sw with location array if boundary is available
        }

        if (state.boundingCoordinates.neLat) {
            parameters.searchQuery.SearchMapNeLat = state.boundingCoordinates.neLat;
            parameters.searchQuery.SearchMapNeLong = state.boundingCoordinates.neLon;
            parameters.searchQuery.SearchMapSwLat = state.boundingCoordinates.swLat;
            parameters.searchQuery.SearchMapSwLong = state.boundingCoordinates.swLon;
        }

        if (!state.results.id) {
            parameters.searchQuery.fetchLdJson = true;
        }
        return new Promise(async (resolve, reject) => {
            // Make the request for the search results
            let params = new URLSearchParams(window.location.search);
            let brand_group_id = null;

            const postParams = {
                pageSize: state.perPage,
                fields: fields,
                page: state.page,
                searchQuery: parameters.searchQuery,
                cancelToken: state.activeRequest.token
            };

            let perPage = state.perPage;
            let totalSize = state.perPage * state.pageSizeMultiplier;
            try {
                const res = await fetchSearch(totalSize, fields, brand_group_id, state.page, parameters, state.activeRequest.token);
                const result = res.data;
                //only reset the pagination pages on subsequent loads (really back to 1, since )
                if (!state.initialLoad) {
                    commit('setCurrentPage', 1);
                }
                commit('setInitialLoad', false);
                //whole thing needs to be rethought
                if (result.items.length < 1) {
                    //none of this if gallery mode... for now
                    if (state.activeViewMode !== 'gallery') {
                        if (state.boundaryEnabled && !state.boundaryModalFired) {
                            setTimeout(() => {
                                if (state.results.count === 0) {
                                    commit('setBoundaryModalFired', true);
                                }
                            }, 3000);
                        } else if (!state.filterModalFired) {
                            if (!state.boundaryEnabled) {
                                setTimeout(() => {
                                    commit('setFilterModalFired', true);
                                }, 1000);
                            }
                        }
                    }
                }
                // set current search query
                commit('setSearchQuery', parameters);
                // map returned object to the
                // results in the vuex store
                commit('setSearchResults', {
                    id: result.id,
                    count: result.count,
                    items: result.items,
                    page: result.page,
                    pageCount: result.pageCount,
                    perPage: result.pageSize / result.pageCount,
                    total: result.totalCount
                });

                commit('setSearchMap', {
                    listingPins: result.listingPins,
                    listingClusters: result.listingClusters
                });
                commit('setSearchRawResults', result);
                // need to investigate this. i think boundary is getting set, which triggers the watcher in searchresultsmap.vue,
                // which breaks searches (causes searches with no boundaries to fiit the searched city's bounds in the viewport)
                // dispatch('geoCodingBoundary');
                dispatch('getCityOwner');
                //some cities have nothing, give them surrounding areas if no boundaries
                // if(result.listingPins.length == 0 && state.boundaryEnabled){
                // 	let removeBoundaryButton = document.getElementById('remove-boundary');
                // 	//future proof if this button goes away
                // 	if(removeBoundaryButton != null && removeBoundaryButton.value == '')
                //
                // 	removeBoundaryButton.click();
                // }
            } catch {
                if (!state.initialLoad) {
                    commit('setCurrentPage', 1);
                }
                commit('setInitialLoad', false);
            } finally {
                setTimeout(() => {
                    resolve();
                    commit('setSearchLoading', false);
                }, 10);
            }
        });
    },

    // set the search location string
    setSearchLocation({ commit, dispatch }, location) {
        commit('setSearchLocation', location);
        dispatch('search');
    },
    // set the search location string
    setSearchAISession({ commit, dispatch }, session) {
        commit('setSearchAISession', session);
    },

    // set the page value
    setSearchPage({ commit, dispatch }, page) {
        commit('setSearchPage', page);
        dispatch('search');
    },

    setSearchRawResults({ commit }, raw) {
        commit('setSearchRawResults', raw);
    },

    // set the sortBy value
    setSearchSortBy({ commit, dispatch }, sortBy) {
        commit('setSearchSortBy', sortBy);
        dispatch('search');
    },

    // Set the search min price value
    setSearchMinPrice({ commit, dispatch }, val) {
        commit('setSearchMinPrice', val);
        dispatch('search');
    },

    // Set the search max price value
    setSearchMaxPrice({ commit, dispatch }, val) {
        commit('setSearchMaxPrice', val);
        dispatch('search');
    }, // Set the public remarks
    setSearchPublicRemarks({ state, commit, dispatch }, val) {
        if (state.filters.publicRemarks !== val) {
            commit('setSearchPublicRemarks', val);
            dispatch('search');
        }
    },

    // Set the search filters
    setSearchFilters({ commit, dispatch }, filters) {
        commit('setSearchFilters', filters);
        dispatch('search');
    },

    // Set the view mode for the search UI
    setSearchViewMode({ commit }, viewMode) {
        commit('setActiveViewMode', viewMode);
    },

    // Set the overlay that should be visible
    setActiveFilterOverlay({ commit }, overlay) {
        commit('setActiveFilterOverlay', overlay);
    },

    // Set the overlay that should be visible
    setSearchMap({ commit }, map) {
        commit('setSearchMap', map);
    },
    setMapInitialized({ commit }, map) {
        commit('setMapInitialized', map);
    },
    setInitialBrandingId({ commit }, value) {
        commit('setInitialBrandingId', value);
    },

    setInitialBrandGroup({ commit }, value) {
        commit('setInitialBrandGroup', value);
    },

    setSearchLender({ commit }, value) {
        commit('setSearchLender', value);
    },
    setSearchAgent({ commit }, value) {
        commit('setSearchAgent', value);
    },

    setInitialLocationId({ commit }, value) {
        commit('setInitialLocationId', value);
    },
    setActivePropertyView({ commit }, value) {
        commit('setActivePropertyView', value);
    },
    setInterstitialState({ commit }, value) {
        commit('setInterstitialState', value);
    },
    // Set the search max price value
    setSearchBoundingCoordinates({ commit, dispatch, state }, coordinatesObj) {
        // verbose to show what the object looks like.
        commit('setSearchBoundingCoordinates', {
            neLat: coordinatesObj.neLat,
            neLon: coordinatesObj.neLon,
            swLat: coordinatesObj.swLat,
            swLon: coordinatesObj.swLon
        });

        return new Promise((resolve, reject) => {
            dispatch('search', state.debounceTimeLimitMapSearch)
                .then((res) => {
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

    // Update the URI on the search page with currently assigned filters.
    updateSearchUriFilters({ state }) {
        // filters to be added to the URI
        let uriFilters = {};

        // current set search filters in vuex store
        let currentFilters = JSON.parse(JSON.stringify(state.filters));
        // default filter settings from vuex store
        let defaultFilters = JSON.parse(JSON.stringify(state.defaultFilters));

        // diff the filters with our default filter settings. We're going to construct a representation
        // of what the URI needs to be set to inside uriFilters.
        Object.keys(currentFilters).forEach(function (filter) {
            if (Array.isArray(currentFilters[filter])) {
                // if the set filter is an array, and our default is undefined, put it in the uri
                if (defaultFilters[filter] === undefined) {
                    uriFilters[filter] = currentFilters[filter];
                }
                // else if we have a default filter that is also an array, toString(); them and set the uri filters
                else if (Array.isArray(defaultFilters[filter]) && currentFilters[filter].sort().toString() != defaultFilters[filter].sort().toString()) {
                    uriFilters[filter] = currentFilters[filter];
                }
            }
            // Else, compare scalars. Yes, this can compare null and undefined, so using non-strict comparison
            else if (currentFilters[filter] != defaultFilters[filter]) {
                uriFilters[filter] = currentFilters[filter];
            }
        });
        // Get the f= variable, and see if we need ot keep it , or eliminiate it.
        let params = new URLSearchParams(document.location.search.substring(1));
        let filterParams = params.get('f');
        if (JSON.stringify(uriFilters) == JSON.stringify({})) {
            if (filterParams) {
                params.delete('f');
                let uriParamString = params.toString();
                if (uriParamString) {
                    history.pushState(null, null, window.location.pathname + '?' + uriParamString);
                } else {
                    history.pushState(null, null, window.location.pathname);
                }
            }
        }
        //if we don't need to remove the f variable, then we can go ahead and set it
        else if (filterParams != JSON.stringify(uriFilters)) {
            params.set('f', JSON.stringify(uriFilters));
            history.pushState(null, null, window.location.pathname + '?' + params.toString());
        }
    },

    async getSearchPublicRecords({ commit }, payload = {}) {
        const { limit = 10, query = '', group_query_param = '', cancelToken = '', setLoadingOff = false, store = 'SearchSuggestions' } = payload;
        try {
            commit(`set${store}Loading`, true);
            commit(`reset${store}`, payload.id);

            let parsedQuery = encodeURIComponent(query);

            let searchUrl = `/search/public_records?limit=${limit}&q=${parsedQuery}`;
            const res = await axios
                .get(searchUrl, {
                    cancelToken: cancelToken
                })
                .catch(function (err) {
                    if (axios.isCancel(err)) {
                        console.log('Previous request canceled, new request is send');
                    }
                });

            if (res) {
                let array = [];
                res.data.forEach((item) => {
                    array.push({
                        Type: 'Address',
                        DisplayName: item.content.address_full,
                        slug: item.content.slug,
                        city: item.content.city,
                        state: item.content.state_id,
                        zip: item.content.postal_code,
                        lat: item.content.point.lat,
                        lng: item.content.point.lon,
                        publicRecordID: item.content.public_record_id
                    });
                });

                commit(`set${store}`, {
                    id: payload.id,
                    type: 'address',
                    data: array
                });
            }
        } catch (e) {
            throw e;
        } finally {
            if (setLoadingOff) {
                commit(`set${store}Loading`, false);
            }
        }
    },
    async getGeoSearchSuggestions({ commit }, payload = {}) {
        commit('setSearchSuggestionsLoading', true);

        const { type = 'city', limit = 10, lat = 0, lon = 0, group_query_param = '', cancelToken = '', setLoadingOff = false } = payload;
        try {
            let searchUrl = '/search-suggestions-geo';
            //let parsedQuery = encodeURIComponent(query);

            searchUrl += `?lat=${lat}&lon=${lon}&limit=${limit}&type=${type}`;

            // check if there is a group_query_param
            if (group_query_param !== '') {
                searchUrl += group_query_param;
            }

            const res = await axios
                .get(searchUrl, {
                    cancelToken: cancelToken
                })
                .catch(function (err) {
                    if (axios.isCancel(err)) {
                        console.log('Previous request canceled, new request is send');
                    }
                });

            if (res) {
                const result = res.data;
                if (type === 'city' && result.success && result.data.length > 0) {
                    commit('setSearchSuggestions', {
                        id: payload.id,
                        type: 'locations',
                        data: result.data.map((v) => {
                            return {
                                item: v,
                                refIndex: 1,
                                score: 1
                            };
                        })
                    });
                }
                if (setLoadingOff) {
                    commit('setSearchSuggestionsLoading', false);
                }
            }
        } catch (error) {
            throw error;
        } finally {
        }
    },
    async getSearchSuggestions({ commit }, payload = {}) {
        commit('setSearchSuggestionsLoading', true);

        const { type = 'city', limit = 10, query = '', group_query_param = '', cancelToken = '', setLoadingOff = false } = payload;
        try {
            let searchUrl = '/search-suggestions';
            let parsedQuery = encodeURIComponent(query);

            searchUrl += `?limit=${limit}&type=${type}&q=${parsedQuery}`;

            // check if there is a group_query_param
            if (group_query_param !== '') {
                searchUrl += group_query_param;
            }

            const res = await axios
                .get(searchUrl, {
                    cancelToken: cancelToken
                })
                .catch(function (err) {
                    if (axios.isCancel(err)) {
                        console.log('Previous request canceled, new request is send');
                    }
                });

            if (res) {
                const result = res.data;
                if (type === 'city' && result.success && result.data.length > 0) {
                    commit('setSearchSuggestions', {
                        id: payload.id,
                        type: 'locations',
                        data: result.data.map((v) => {
                            return {
                                item: v,
                                refIndex: 1,
                                score: 1
                            };
                        })
                    });
                }
                if ((type === 'community' || type === 'neighborhood') && result.success && result.data.length > 0) {
                    commit('setSearchSuggestions', {
                        id: payload.id,
                        type: 'community',
                        data: result.data
                    });
                }
                if (type === 'school' && result.success && result.data.length > 0) {
                    commit('setSearchSuggestions', {
                        id: payload.id,
                        type: 'school',
                        data: result.data
                    });
                }
                if (type === 'zip' && result.success && result.data.length > 0) {
                    commit('setSearchSuggestions', {
                        id: payload.id,
                        type: 'zips',
                        data: result.data
                    });
                }
                if (type === 'address' && result.success && result.data.length > 0) {
                    commit('setSearchSuggestions', {
                        id: payload.id,
                        type: 'address',
                        data: result.data
                    });
                }
                if (type === 'addressactive' && result.success && result.data.length > 0) {
                    commit('setSearchSuggestions', {
                        id: payload.id,
                        type: 'addressactive',
                        data: result.data
                    });
                }
                if (type === 'mls' && result.success && result.data.length > 0) {
                    commit('setSearchSuggestions', {
                        id: payload.id,
                        type: 'mls',
                        data: result.data
                    });
                }
                if (setLoadingOff) {
                    commit('setSearchSuggestionsLoading', false);
                }
            }
        } catch (error) {
            throw error;
        } finally {
        }
    },

    async getGoogleSearchSuggestions({ commit }, payload = {}) {
        try {
            commit('setSearchSuggestionsLoading', true);
            commit('resetSearchSuggestions', payload.id);
            const { query = '', cancelToken = '' } = payload;
            let parsedQuery = encodeURIComponent(query);
            const googleUrl = '/geocoding/location' + '?address_only=true&location=' + parsedQuery;
            const res = await axios.post(googleUrl, {
                cancelToken: cancelToken.token
            });
            const { data, success } = res.data;
            if (success) {
                const googleValues =
                    data.data.results.map((v) => ({
                        ...v,
                        Type: 'Google'
                    })) || [];
                commit('setSearchSuggestions', {
                    type: 'google',
                    data: googleValues
                });
            }
        } catch (error) {
            throw error;
        } finally {
            commit('setSearchSuggestionsLoading', false);
        }
    },

    async getSearchSuggestionsForAll({ commit, dispatch }, payload = {}) {
        commit('resetSearchSuggestions', payload.id);
        commit('setSearchSuggestionsLoading', true);
        let isCity = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/.test(payload.query);
        let isZip = /^\d+$/.test(payload.query) && payload.query.toString().length <= 5;
        let isMLS = /^([a-z]{2}\-\d{4,12}|([a-z]{0,2}|[a-z]{4})?\d{6,12}([a-z]{1,2})?|\d{2}\-\d{4,12})$/i.test(payload.query);

        const promises = [
            isCity
                ? dispatch('getSearchSuggestions', {
                      ...payload,
                      type: 'city'
                  })
                : '', // get city suggestions
            isCity
                ? dispatch('getSearchSuggestions', {
                      ...payload,
                      type: 'school'
                  })
                : '', // get school suggestions
            isCity
                ? dispatch('getSearchSuggestions', {
                      ...payload,
                      type: 'community'
                  })
                : '', // get community suggestions
            isZip
                ? dispatch('getSearchSuggestions', {
                      ...payload,
                      type: 'zip'
                  })
                : '', // get zip suggestions
            dispatch('getSearchSuggestions', {
                ...payload,
                type: 'addressactive'
            }), // get address suggestions
            isMLS
                ? dispatch('getSearchSuggestions', {
                      ...payload,
                      type: 'mls'
                  })
                : '' // get mls suggestions
        ];
        try {
            const [addressResults, mlsResults, cityResults, schoolResults, zipResults, communityResults] = await Promise.all(promises);
            return {
                cityResults,
                addressResults,
                mlsResults,
                schoolResults,
                zipResults,
                communityResults
            };
        } catch (error) {
            throw error;
        } finally {
            commit('setSearchSuggestionsLoading', false);
        }
    },

    // add to favorites
    async toggleUserFavorite({ commit, dispatch }, payload = {}) {
        try {
            const { listingKey, isFavorite, external } = payload;
            const res = isFavorite ? await favoriteProperty(listingKey, external) : await unfavoriteProperty(listingKey, external);
            const { success } = res;
            if (success) {
                commit('setFavorite', {
                    listingKey,
                    isFavorite: isFavorite
                });
            }
            // return promise
            return res;
        } catch (error) {
            throw error;
        }
    },
    // add to favorites
    async saveSearch({ commit, dispatch }, payload = {}) {
        try {
            return postSaveSearch(payload);
        } catch (error) {
            throw error;
        }
    },
    setViewedListing({ commit }, payload = {}) {
        commit('setViewedListing', payload);
    },
    async getAgentSearchSuggestions({ commit }, payload = {}) {
        try {
            commit('setAgentSearchSuggestionsLoading', true);
            commit('resetAgentSearchSuggestions');
            const { query = '', cancelToken = '', type = '' } = payload;
            console.log('type', type);
            console.log('payload', payload);
            let parsedQuery = encodeURIComponent(query);
            const agentUrl = '/AgentSearchES?limit=50' + '&q=' + parsedQuery + '&type=' + type;
            const res = await axios.get(agentUrl, {
                cancelToken: cancelToken.token
            });
            const { data, success } = res.data;

            if (success) {
                const agentValues =
                    data.map((v) => ({
                        ...v,
                        Type: 'Agent'
                    })) || [];
                commit('setAgentSearchSuggestions', {
                    type: 'agent',
                    data: agentValues
                });
            }
        } catch (error) {
            throw error;
        } finally {
            commit('setAgentSearchSuggestionsLoading', false);
        }
    },
    async getPoiSearchSuggestions({ commit }, payload = {}) {
        try {
            commit('setPoiSearchSuggestionsLoading', true);
            commit('resetPoiSearchSuggestions');
            const { query = '', cancelToken = '' } = payload;
            let parsedQuery = encodeURIComponent(query);
            let lat = payload.lat;
            let lon = payload.lon;
            let poiUrl = 'pelias/autocomplete?text=' + parsedQuery;
            if (lat && lon) {
                poiUrl += `&focus.point.lat=${lat}&focus.point.lon=${lon}`;
            }
            const res = await axios.get(poiUrl, {
                cancelToken: cancelToken.token
            });
            const { data, success } = res;

            commit('setPoiSearchSuggestions', {
                type: 'poi',
                data: data.features
            });
        } catch (error) {
            throw error;
        } finally {
            commit('setPoiSearchSuggestionsLoading', false);
        }
    },
    setActionLoadingStatus({ commit }, payload) {
        commit('setActionLoadingStatus', payload);
    },
    setAds({ commit }, payload) {
        commit('setAds', payload);
    },
    setSearchVanities(state, payload) {
        state.searchVanities = payload;
    },
    setCurrentSearchVanity(state, payload) {
        state.currentSearchVanity = payload;
    }
};
