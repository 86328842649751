import Vue from 'vue';

import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import sharedMutations from 'vuex-shared-mutations';

import listing from './modules/listing';
import search from './modules/search';
// import store modules
import tracking from './modules/tracking';
import user from './modules/user';

Vue.use(Vuex);

// let ls = new SecureLS({ isCompression: true });

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        tracking,
        search,
        user,
        listing
    },
    strict: debug,
    plugins: [
        createPersistedState({
            // reducer: (state) => ({
            //     entranceData: state.tracking.entranceData,
            //     currentData: state.tracking.currentData,
            // }),
            paths: ['tracking', 'user', 'search.mapLayerStyle']
            // storage: {
            //     getItem: (key) => ls.get(key),
            //     setItem: (key, value) => ls.set(key, value),
            //     removeItem: (key) => ls.remove(key),
            // },
        }),
        sharedMutations({
            predicate: ['tracking/setEntranceData', 'tracking/setCurrentData', 'tracking/setLocationDistance', 'tracking/setLocationData', 'tracking/setPropertyParams']
        })
    ]
});
