import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = () => ({
    searchSuggestions: [],
    searchSuggestionsLoading: false,
    activeFilterOverlay: null,
    activeViewMode: 'gallery',
    activeRequest: null,
    activePropertyView: {},
    agent: null,
    stateDisclaimer: null,
    locationInfo: null,
    currentPage: 1,
    debounceTimeLimitDefault: 10, // time in ms
    debounceTimeLimitMapSearch: 10, // time in ms
    debounceTimer: null,
    boundaryEnabled: true,
    boundaryModalFired: false,
    filterModalFired: false,
    mapLayerStyle: null,
    filters: {
        daysOnMarket: null,
        extendedCriteria: [],
        listingTypes: ['residential', 'land', 'multi_family'],
        maxHoa: null,
        maxLotSize: null,
        maxPrice: null,
        maxSqf: null,
        maxYearBuilt: null,
        minBathrooms: null,
        minBedrooms: null,
        minLotSize: null,
        minPrice: null,
        minSqf: null,
        minYearBuilt: null,
        openHouseNextXDays: null,
        publicRemarks: null,
        hasMatterport: null,
        propertyStatus: ['active'],
        propertyTypes: ['single_family', 'condos', 'townhouses', 'farm', 'multi_family', 'manufactured', 'land_lots', 'other'],
        sortBy: 'date_listed'
    },
    boundingCoordinates: {
        neLat: null,
        neLon: null,
        swLat: null,
        swLon: null
    },
    defaultFilters: {
        listingTypes: ['residential', 'land', 'multi_family'],
        extendedCriteria: [],
        propertyStatus: ['active'],
        propertyTypes: ['single_family', 'condos', 'townhouses', 'farm', 'multi_family', 'manufactured', 'land_lots', 'other'],
        sortBy: 'date_listed'
    },
    lender: null,
    loading: false,
    location: {
        city: '',
        query: '',
        state: '',
        type: '',
        value: '',
        displayText: '',
        lat: null, // helpful for fly-to bits where we have to do an ajax search to pull things nearby the search location
        lng: null
    },
    initialLoad: true,
    interstitialState: {
        show: false,
        id: null,
        isFavorite: 0,
        property: {}
    },
    map: {
        listingClusters: [],
        listingPins: [],
        latLng: [],
        boundary: []
    },
    mapInitialized: false,
    page: 1,
    pageSizeMultiplier: 10, // sets the pagination size and fills results to that (perPage * pageSizeMultiplier)
    perPage: 20, // number of results to show in pagination
    raw: null,
    results: {
        id: null,
        count: 0,
        items: [],
        paginatedItems: [],
        page: 1,
        pageCount: 26,
        perPage: 25,
        total: 0
    },
    searchQuery: {},
    viewedListings: [],
    agentSearchSuggestions: [],
    agentSearchSuggestionsLoading: false,
    actionLoadingStatus: {},
    poiSearchSuggestionsLoading: false,
    poiSearchSuggestions: [],
    publicRecordsSearchSuggestions: [],
    publicRecordsSearchSuggestionsLoading: false,
    ads: [],
    defaultVanities: [
        {
            name: 'luxury-homes',
            descriptionTitle: 'Luxury Homes for Sale',
            searchUrl: 'luxury-homes'
        },
        {
            name: 'commercial',
            descriptionTitle: 'Commercial Property for Sale',
            searchUrl: 'commercial'
        },
        {
            name: 'condos',
            descriptionTitle: 'Condos for Sale',
            searchUrl: 'condos'
        },
        {
            name: 'homes-with-pools',
            descriptionTitle: 'Homes With Pools for Sale',
            searchUrl: 'homes-with-pools'
        },
        {
            name: 'land',
            descriptionTitle: 'Land for Sale',
            searchUrl: 'land'
        },
        {
            name: 'cheap-homes',
            descriptionTitle: 'Cheap Homes for Sale',
            searchUrl: 'cheap-homes'
        },
        {
            name: 'new-homes',
            descriptionTitle: 'New Construction Homes for Sale',
            searchUrl: 'new-homes'
        },
        {
            name: 'two-car-garage-homes',
            descriptionTitle: '2 Car Garage Homes for Sale',
            searchUrl: 'two-car-garage-homes'
        },
        {
            name: 'single-story-homes',
            descriptionTitle: 'Single Story Homes for Sale',
            searchUrl: 'single-story-homes'
        },
        {
            name: 'town-homes',
            descriptionTitle: 'Town Homes for Sale',
            searchUrl: 'town-homes'
        },
        {
            name: 'open-house',
            descriptionTitle: 'Open Houses for Sale',
            searchUrl: 'open-house'
        },
        {
            name: 'large-lots',
            descriptionTitle: 'Homes with lots larger than 1/2 acre for Sale',
            searchUrl: 'large-lots'
        }
    ],
    searchVanities: [],
    currentSearchVanity: null
});

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
