import { annotate, annotationGroup } from '@slidev/rough-notation';

function addClass(options, cls) {
    options.class = [options.class, cls].filter(Boolean).join(' ');
    return options;
}

const vMarkModifiers = {
    // Types
    box: (options) => Object.assign(options, { type: 'box' }),
    circle: (options) => Object.assign(options, { type: 'circle' }),
    underline: (options) => Object.assign(options, { type: 'underline' }),
    highlight: (options) => Object.assign(options, { type: 'highlight' }),
    'strike-through': (options) => Object.assign(options, { type: 'strike-through' }),
    'crossed-off': (options) => Object.assign(options, { type: 'crossed-off' }),
    bracket: (options) => Object.assign(options, { type: 'bracket' }),

    // Type Aliases
    strike: (options) => Object.assign(options, { type: 'strike-through' }),
    cross: (options) => Object.assign(options, { type: 'crossed-off' }),
    crossed: (options) => Object.assign(options, { type: 'crossed-off' }),
    linethrough: (options) => Object.assign(options, { type: 'strike-through' }),
    'line-through': (options) => Object.assign(options, { type: 'strike-through' }),

    // Colors
    black: (options) => Object.assign(options, { color: 'black' }),
    blue: (options) => Object.assign(options, { color: 'blue' }),
    cyan: (options) => Object.assign(options, { color: 'cyan' }),
    gray: (options) => Object.assign(options, { color: 'gray' }),
    green: (options) => Object.assign(options, { color: 'green' }),
    indigo: (options) => Object.assign(options, { color: 'indigo' }),
    lime: (options) => Object.assign(options, { color: 'lime' }),
    orange: (options) => Object.assign(options, { color: 'orange' }),
    pink: (options) => Object.assign(options, { color: 'pink' }),
    purple: (options) => Object.assign(options, { color: 'purple' }),
    red: (options) => Object.assign(options, { color: 'red' }),
    teal: (options) => Object.assign(options, { color: 'teal' }),
    white: (options) => Object.assign(options, { color: 'white' }),
    yellow: (options) => Object.assign(options, { color: 'yellow' })
};

const vMarkModifiersDynamic = [
    [
        /^delay-?(\d+)?$/,
        (match, options, value) => {
            const ms = (match[1] ? Number.parseInt(match[1]) : value) || 300;
            options.delay = ms;
            return options;
        }
    ],
    [
        /^(?:op|opacity)-?(\d+)?$/,
        (match, options, value) => {
            const opacity = (match[1] ? Number.parseInt(match[1]) : value) || 100;
            options.opacity = opacity / 100;
            return options;
        }
    ]
];

export default {
    inserted: function (el, binding) {
        const bindingOptions = typeof binding.value === 'object' && !Array.isArray(binding.value) ? { ...binding.value } : { at: binding.value };

        let options = {
            ...bindingOptions
        };

        if (binding.arg) {
            options.type = binding.arg;
            if (binding.arg === 'circle') {
                options.strokeWidth = 5;
            }
        }

        const unknownModifiers = Object.entries(binding.modifiers).filter(([k, v]) => {
            if (vMarkModifiers[k]) {
                options = vMarkModifiers[k](options, v);
                return false;
            }
            for (const [re, fn] of vMarkModifiersDynamic) {
                const match = k.match(re);
                if (match) {
                    options = fn(match, options, v);
                    return false;
                }
            }
            return true;
        });

        if (unknownModifiers.length) console.warn('[Slidev] Invalid modifiers for v-mark:', unknownModifiers);

        options.type ||= 'underline';
        options.color ||= '#fdb900';
        options.strokeWidth ||= 7;

        const annotation = annotate(el, options);
        const ag = annotationGroup([annotation]);

        const loopAnimation = binding.value?.loop === 'true' || binding.value?.loop === true || false;

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    ag.show();
                    if (!loopAnimation) {
                        observer.unobserve(el);
                    }
                } else if (loopAnimation) {
                    ag.hide();
                }
            });
        });

        observer.observe(el);

        // Wrap the element with a container for proper scaling and positioning
        const wrapper = document.createElement('div');
        wrapper.style.position = 'relative';
        wrapper.style.display = 'inline';
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);

        // Redraw annotation when the window is resized to prevent it from being misplaced
        const resizeListener = () => {
            ag.hide();
            ag.show();
        };
        window.addEventListener('resize', resizeListener);

        el._resizeListener = resizeListener;
        el._observer = observer;
        el._wrapper = wrapper;

        // Insert the annotation SVG into the wrapper
        if (annotation._svg) {
            wrapper.appendChild(annotation._svg);
        }
    },
    unbind: function (el) {
        // Clean up the IntersectionObserver and resize event listener
        if (el._observer) {
            el._observer.unobserve(el);
            el._observer.disconnect();
        }
        if (el._resizeListener) {
            window.removeEventListener('resize', el._resizeListener);
        }
        if (el._wrapper && el._wrapper.parentNode) {
            el._wrapper.parentNode.insertBefore(el, el._wrapper);
            el._wrapper.remove();
        }
    }
};
