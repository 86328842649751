export default {
    // set an axios request to the activeRequest to allow search to cancel previous requests
    setActiveRequest(state, newRequest) {
        state.activeRequest = newRequest;
    },
    setInitialLoad(state, load) {
        state.initialLoad = load;
    },
    setDebounceTimer(state, timer) {
        if (state.debounceTimer) {
            clearTimeout(state.debounceTimer);
        }
        state.debounceTimer = timer;
    },
    setSearchQuery(state, query) {
        state.searchQuery = query;
    },

    setSearchAgent(state, agent) {
        state.agent = agent;
    },
    setStateDisclaimer(state, stateDisclaimer) {
        state.stateDisclaimer = stateDisclaimer;
    },
    setActiveViewMode(state, viewMode) {
        state.activeViewMode = viewMode;
    },
    setActiveFilterOverlay(state, overlay) {
        state.activeFilterOverlay = overlay;
    },
    setSearchFilters(state, filters) {
        state.filters = { ...state.filters, ...filters };
        // update window url
        if (!filters) return;
        // mutate window url
        // call updateWindowUrl function
        this.commit('search/updateWindowUrl');
    },
    setSearchMinPrice(state, val) {
        state.filters.minPrice = val;
    },
    setSearchMaxPrice(state, val) {
        state.filters.maxPrice = val;
    },
    setSearchPublicRemarks(state, val) {
        state.filters.publicRemarks = val;
    },
    setBoundaryEnabled(state, val) {
        state.boundaryEnabled = val;
    },
    setBoundaryModalFired(state, val) {
        if (state.boundaryEnabled) {
            state.boundaryModalFired = val;
        }
    },
    setFilterModalFired(state, val) {
        state.filterModalFired = val;
    },
    setMapLayerStyle(state, val) {
        state.mapLayerStyle = val;
    },
    setSearchLender(state, lender) {
        state.lender = lender;
    },
    setSearchLocationInfo(state, locationInfo) {
        state.locationInfo = locationInfo;
    },

    setSearchLoading(state, loading) {
        state.loading = loading;
    },
    setSearchAISession(state, aiSessionId) {
        state.aiSessionId = aiSessionId;
    },

    setSearchLocation(state, location) {
        if (location.city) {
            location.city = location.city.charAt(0).toUpperCase() + location.city.slice(1);
        }
        if (location.state) {
            location.state = location.state.toUpperCase();
        }
        if (location.lat && location.lng) {
            location.lat = parseFloat(location.lat);
            location.lng = parseFloat(location.lng);
        }
        state.location = { ...state.location, ...location };
    },
    setSearchMap(state, map) {
        state.map = { ...state.map, ...map };
        state.map.initialized = true;
    },
    setMapInitialized(state, initialized) {
        state.mapInitialized = initialized;
    },
    setSearchPage(state, page) {
        state.page = page;
    },
    setSearchRawResults(state, raw) {
        state.raw = raw;
    },
    setSearchResults(state, results) {
        state.results = { ...state.results, ...results };

        const resultItems = state.results.items;
        const { perPage } = state;

        state.results.paginatedItems = Array.from({ length: Math.ceil(resultItems.length / perPage) }, (v, i) => resultItems.slice(i * perPage, i * perPage + perPage));
    },
    setSearchSortBy(state, sortBy) {
        state.filters.sortBy = sortBy;
        // mutate window url
        // call updateWindowUrl function
        this.commit('search/updateWindowUrl');
    },

    updateWindowUrl(state) {
        const UTM_params = ['utm_campaign', 'utm_content', 'utm_term', 'utm_source', 'twclid', 'gclid', 'fbclid', 'utm_medium', 'forced-reg', 'gbraid', 'gad_source'];

        // update window url
        const url = new URL(window.location.href);

        // check if there are any UTM params in the url then make a object of them
        const UTM_params_obj = {};
        UTM_params.forEach((param) => {
            if (url.searchParams.get(param)) {
                UTM_params_obj[param] = url.searchParams.get(param);
            }
        });

        const searchFilters = encodeURIComponent(JSON.stringify(state.filters));
        // remove null attributes from params
        const paramsObj = JSON.parse(decodeURIComponent(searchFilters));
        Object.keys(paramsObj).forEach((key) => paramsObj[key] == null && delete paramsObj[key]);
        const params = encodeURIComponent(JSON.stringify(paramsObj));
        // add to browser history
        var stateURL = `${url.pathname}?f=${params}`;
        if (state.aiSessionId) {
            stateURL += `&s=${state.aiSessionId}`;
        }
        // add UTM params to url
        Object.keys(UTM_params_obj).forEach((key) => {
            stateURL += `&${key}=${UTM_params_obj[key]}`;
        });
        window.history.pushState({ params }, '', stateURL);
    },

    setSearchBoundingCoordinates(state, coordinatesObj) {
        state.boundingCoordinates = {
            ...state.boundingCoordinates,
            ...coordinatesObj
        };
    },
    // branding setters
    setCurrentPage(state, page) {
        state.currentPage = page;
    },
    setSearchSuggestions(state, payload = {}) {
        const { type, data, id } = payload;

        if (typeof state.searchSuggestions !== 'object') {
            state.searchSuggestions = {};
        }

        if (!state.searchSuggestions[id]) {
            state.searchSuggestions = {
                ...state.searchSuggestions,
                [id]: [{ name: type, data }]
            };
        } else {
            state.searchSuggestions[id] = [...state.searchSuggestions[id], { name: type, data }];
        }

        state.searchSuggestions[id].sort((a, b) => (a.name === 'locations' ? -1 : b.name === 'locations' ? 1 : 0));
    },

    resetSearchSuggestions(state, id = null) {
        if (id && state.searchSuggestions[id]) {
            // Reset only the suggestions for the specific id
            state.searchSuggestions[id] = [];
        } else if (!id) {
            console.log('Resetting all search suggestions');
            // If no id is provided, reset the entire searchSuggestions object
            state.searchSuggestions = {};
        }
    },
    setSearchSuggestionsLoading(state, payload) {
        state.searchSuggestionsLoading = payload;
    },
    setActivePropertyView(state, property) {
        state.activePropertyView = property;
    },
    setInterstitialState(state, val) {
        state.interstitialState = { ...state.val, ...val };
    },
    setFavorite(state, payload) {
        const { listingKey, isFavorite } = payload;

        if (state.activePropertyView.internal_property_id === listingKey) {
            state.activePropertyView.isFav = isFavorite;
        }

        state.results.items = state.results.items.map((item) => {
            if (item.listingKey === listingKey) {
                item.isFav = isFavorite;
            }
            return item;
        });

        state.results.paginatedItems = state.results.paginatedItems.map((page) =>
            page.map((item) => {
                if (item.listingKey === listingKey) {
                    item.isFav = isFavorite;
                }
                return item;
            })
        );
    },
    setViewedListings(state, payload) {
        // use payload.key as key to avoid duplicates
        state.viewedListings = [...state.viewedListings, payload];
    },
    resetAgentSearchSuggestions(state) {
        state.agentSearchSuggestions = [];
    },
    resetPoiSearchSuggestions(state) {
        state.poiSearchSuggestions = [];
    },
    resetPublicRecordsSearchSuggestions(state) {
        state.publicRecordsSearchSuggestions = [];
    },
    setAgentSearchSuggestionsLoading(state, payload) {
        state.agentSearchSuggestionsLoading = payload;
    },
    setPoiSearchSuggestionsLoading(state, payload) {
        state.poiSearchSuggestionsLoading = payload;
    },
    setPublicRecordsSearchSuggestionsLoading(state, payload) {
        state.publicRecordsSearchSuggestionsLoading = payload;
    },
    setAgentSearchSuggestions(state, payload = {}) {
        const { type, data } = payload;
        state.agentSearchSuggestions.push({
            name: type,
            data
        });
    },
    setPoiSearchSuggestions(state, payload = {}) {
        const { type, data } = payload;
        state.poiSearchSuggestions.push({
            name: type,
            data
        });
    },
    setPublicRecordsSearchSuggestions(state, payload = {}) {
        const { type, data } = payload;
        state.publicRecordsSearchSuggestions.push({
            name: type,
            data
        });
    },
    setActionLoadingStatus(state, payload) {
        state.actionLoadingStatus = payload;
    },
    setAds(state, ads) {
        state.ads = ads;
    },
    setSearchVanities(state, vanities) {
        state.searchVanities = vanities;
    },
    setCurrentSearchVanity(state, vanity) {
        state.currentSearchVanity = vanity;
    }
};
