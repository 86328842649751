import _axios from 'axios';
import axiosRetry from 'axios-retry';
import config from './config';
const baseURL = config.API_BASE_URL;
const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const axios = _axios.create({
    baseURL,
    headers: { 'X-Requested-With': 'XMLHttpRequest', 'X-CSRF-TOKEN': csrfToken },
    ...(token && { headers: { Authorization: 'Bearer ' + token } })
});

axios.CancelToken = _axios.CancelToken;
axios.isCancel = _axios.isCancel;

const retryDelay = ({ retryNumber }) => Math.pow(2, retryNumber) * 1000 + Math.random() * 1000;

axiosRetry(axios, {
    retries: 2,
    retryDelay,
    retryCondition: axiosRetry.isRetryableError,
    onRetry: (retryCount, error, requestConfig) => {
        let url = requestConfig.url;

        // Increment _retry if it exists, otherwise add it
        url = url.replace(/([?&]_retry=)(\d+)/, (match, prefix, currentRetry) =>
            `${prefix}${parseInt(currentRetry, 10) + 1}`
        );

        if (!/_retry=/.test(url)) {
            url += (url.indexOf('?') !== -1 ? '&' : '?') + `_retry=${retryCount}`;
        }

        requestConfig.url = url;
    }
});

export default axios;
