import Cookie from 'js-cookie';
export default {
    methods: {
        $_trackEvent(eventName = 'custom-event', category = 'Custom Event', action = 'clicked', label = '', data = 0) {
            let crawlerAgentRegex = /bingbot|amazonbot|google|lighthouse|msnbot|pingdom|HeadlessChrome|rendertron/i,
                cIP,
                gOp = 'false';
            if (crawlerAgentRegex.test(navigator.userAgent)) {
                return;
            }
            this.$_googleTrack(eventName, category, action, label, data);
        },

        //google tag manager tracker
        $_googleTrack(eventName = 'custom-event', category = 'Custom Event', action = 'clicked', label = '', data = 0) {
            //we can track FB actions within the GTM itself by passing fbp and fbc as params in the data object

            let fbCid = this.$_getFacebookClickId();
            let fbPid = this.$_getFacebookPixelId();
            //track pageview nam
            gtag('event', eventName);
            dataLayer.push({ event: eventName, category: category, action: action, label: label, value: data });
        },
        //this gets facebook click param
        $_getFacebookClickId() {
            let id = null;
            id = Cookie.get('_fbc');
            if (!id) {
                let paramGrabber = new URLSearchParams(window.location.search);
                paramGrabber = paramGrabber.get('fbclid');
                if (paramGrabber) {
                    //set the cookie too. It can be overwritten when another link comes in or if they fb elsewhere
                    id = paramGrabber;
                    Cookie.set(id, '_fbc');
                }
            }
            return id;
        },
        //what it says on the can, get the _fbp id cookie to pass into gtm
        $_getFacebookPixelId() {
            let id = null;
            id = Cookie.get('_fbp');
            return id;
        }
    }
};
