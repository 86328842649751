import Vue from 'vue';
import dayjs from 'dayjs';

const exposedNumeral = (value, format) => {
    let formatter = Intl.NumberFormat('en');
    return formatter.format(value);
};
const convertToSlug = (value) =>
    value
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
const convertSpaceToDash = (value) => value.replace(/\s/g, '-');
const convertCaseToSpace = (value) => value.replace(/([A-Z])/g, (match) => ` ${match}`);
const convertCommaToSpace = (value) => value.replace(/,(?=[^\s])/g, ', ');
const convertEmptyResponse = (value) => (value ? value : '-');
const filterResponse = (value) => (value ? value : '');
const formatDate = (value) => dayjs(value).format('MM/DD/YYYY');
const formatDateOnlyMonthYear = (value) => dayjs(value).format('MMMM YYYY');
const capitalizeFirstLetter = (value) => value.charAt(0).toUpperCase() + value.slice(1);
const formatNumber = (value) => {
    let formatter = Intl.NumberFormat('en');
    return formatter.format(value);
};
// 10 to 10 AM  13 to 1PM
const formatTimeToAmPm = (value) => {
    value = parseInt(value);
    if (value > 11) {
        if (value == 12) return `${value}pm`;
        return `${value - 12}pm`;
    }
    return `${value}am`;
};
const formatNumberWithPrefix = (value, prefix = '') => {
    if (!isNaN(value)) {
        let formatter = Intl.NumberFormat('en');
        return prefix + formatter.format(value);
    } else {
        return 'N/A';
    }
};
const formatNumberWithSuffix = (value, suffix = '') => {
    if (!isNaN(value)) {
        if (value > 1000000) {
            let formatter = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 0 });
            return formatter.format(value) + suffix;
        } else {
            let formatter = Intl.NumberFormat('en', { notation: 'compact' });
            return formatter.format(value) + suffix;
        }
    } else {
        return 'N/A';
    }
};
const calculateDifferenceInDates = (date, type = 'month', round_down = false) => {
    const dateNow = dayjs();
    const dateDiff = dayjs(dateNow).diff(date, type);
    if (round_down) {
        return Math.floor(dateDiff);
    }
    return dateDiff;
};

const filters = {
    exposedNumeral,
    convertSpaceToDash,
    convertCaseToSpace,
    convertCommaToSpace,
    convertEmptyResponse,
    formatDate,
    capitalizeFirstLetter,
    formatNumber,
    formatNumberWithPrefix,
    formatNumberWithSuffix,
    convertToSlug,
    formatDateOnlyMonthYear,
    filterResponse,
    calculateDifferenceInDates,
    formatTimeToAmPm
};

Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});
