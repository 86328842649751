// enhanced-history-plugin.js

const HistoryPlugin = {
    install(Vue) {
        const EventBus = new Vue();
        let lastHistoryState = window.location.href;
        let isHandlingStateChange = false;
        let initialSpaState = null;

        const history = {
            lastHistoryState: window.location.href,
            initialState: null,
            currentState: null,
            isNavigatingThroughHistory: false,

            push(url, state) {
                if ((window.location.href === url && this.initialState) || this.isNavigatingThroughHistory) {
                    return; // Prevents pushing the same URL again
                }
                this.isNavigatingThroughHistory = true;
                if (!initialSpaState) {
                    initialSpaState = { url, state };
                }
                window.history.pushState(state, '', url);
                this.handleStateChange('PUSH', state, url);
            },

            replace(url, state) {
                if ((window.location.href === url && this.initialState) || this.isNavigatingThroughHistory) {
                    return; // Prevents pushing the same URL again
                }
                this.isNavigatingThroughHistory = true;
                if (!initialSpaState) {
                    initialSpaState = { url, state };
                }
                window.history.replaceState(state, '', url);
                this.handleStateChange('REPLACE', state, url);
            },

            back() {
                window.history.back();
            },

            forward() {
                window.history.forward();
            },

            on(event, callback) {
                EventBus.$on(event, callback);
            },

            off(event, callback) {
                EventBus.$off(event, callback);
            },

            setInitialState(state) {
                this.replace(window.location.href, state);
                this.initialState = state;
                this.currentState = state;
            },

            handleStateChange(action, state, url) {
                if (isHandlingStateChange) return;
                isHandlingStateChange = true;

                const from = lastHistoryState;
                lastHistoryState = url;
                this.currentState = state;

                EventBus.$emit('history-change', {
                    action,
                    from,
                    to: url,
                    state,
                    isInitialSpaState: url === initialSpaState?.url,
                    hasSearchState: this.initialState && this.initialState.searchView
                });

                Vue.nextTick(() => {
                    isHandlingStateChange = false;
                    this.isNavigatingThroughHistory = false;
                });
            },

            handleNavigation(url, state) {
                if (this.isSpaNavigation(url)) {
                    this.push(url, state);
                } else {
                    window.location.href = url;
                }
            },

            isSpaNavigation() {
                return document.body.dataset?.pageName === 'search';
            },

            handlePopState(event) {
                this.isNavigatingThroughHistory = true;
                const state = event.state || {};
                if (this.isSpaNavigation()) {
                    this.handleStateChange('POP', state, window.location.href);
                } else {
                    // Instead of full reload, check if the SPA can handle it
                    this.handleNavigation(window.location.href, state);
                }
            },

            debugger() {
                const original = {
                    pushState: window.history.pushState,
                    replaceState: window.history.replaceState
                };

                function getStackTrace() {
                    const error = new Error();
                    return error.stack.split('\n').slice(3).join('\n');
                }

                function logStateChange(type, ...args) {
                    const [state, title, url] = args;
                    const stack = getStackTrace();

                    console.group(`%cHistory ${type}`, 'color: #4CAF50; font-weight: bold;');
                    console.log('State:', state);
                    console.log('Title:', title);
                    console.log('URL:', url);
                    console.log('Stack Trace:');
                    console.log(stack);
                    console.groupEnd();
                }

                window.history.pushState = function (...args) {
                    logStateChange('pushState', ...args);
                    return original.pushState.apply(this, args);
                };

                window.history.replaceState = function (...args) {
                    logStateChange('replaceState', ...args);
                    return original.replaceState.apply(this, args);
                };

                window.addEventListener('popstate', function (event) {
                    const stack = getStackTrace();

                    console.group('%cPopstate Event', 'color: #2196F3; font-weight: bold;');
                    console.log('State:', event.state);
                    console.log('URL:', location.href);
                    console.log('Stack Trace:');
                    console.log(stack);

                    console.groupEnd();
                });

                console.log('%cHistory API Logger Activated', 'color: #4CAF50; font-weight: bold; font-size: 14px;');
            }
        };

        Vue.prototype.$history = history;

        window.addEventListener('popstate', (event) => history.handlePopState(event));

        // Check if History API is supported
        const isHistoryApiSupported = () => {
            const isChrome = window.chrome && window.chrome.app && window.chrome.app.runtime;
            const hasHistoryApi = 'history' in window && !!window.history.pushState && !!window.history.replaceState;
            return !isChrome && hasHistoryApi;
        };

        if (isHistoryApiSupported()) {
            console.log('Enhanced History API initialized');
            // history.debugger(); // Activate the debugger
        } else {
            console.warn('History API not supported in this browser');
        }
    }
};

export default HistoryPlugin;
