export default {
    results: (state) => state.results,
    filters: (state) => state.filters,
    defaultFilters: (state) => state.filters,
    lender: (state, _, rootState) => {
        // check if user is from sales team
        // if yes and rootState.user.demoLenderData is not empty
        // return rootState.user.demoLenderData
        // else return state.lender
        if (rootState.user.user?.preferences?.sales && Object.keys(rootState.user.demoLenderData).length > 0) {
            return rootState.user.demoLenderData;
        }
        return state.lender;
    },
    agent: (state, _, rootState) => {
        // check if user is from sales team
        // if yes and rootState.user.demoAgentData is not empty
        // return rootState.user.demoAgentData
        // else return state.agent

        if (rootState.user.user?.preferences?.sales && Object.keys(rootState.user.demoAgentData).length > 0) {
            return rootState.user.demoAgentData;
        }
        return state.agent;
    },
    locationInfo: (state) => state.locationInfo,
    currentPage: (state) => state.currentPage,
    page: (state) => state.page,
    setInitialLoad: (state) => state.setInitialLoad,
    location: (state) => state.location,
    aiSessionId: (state) => state.aiSessionId,
    raw: (state) => state.raw,
    stateDisclaimer: (state) => state.stateDisclaimer,
    activeViewMode: (state) => state.activeViewMode,
    boundaryEnabled: (state) => state.boundaryEnabled,
    searchQuery: (state) => state.searchQuery,
    loading: (state) => state.loading,
    paginatedItems: (state) => state.results.paginatedItems,
    perPage: (state) => state.results.perPage,
    listingPins: (state) => state.map.listingPins,
    listingClusters: (state) => state.map.listingClusters,
    boundary: (state) => state.map.boundary,
    latLng: (state) => state.map.latLng,
    boundaryModalFired: (state) => state.boundaryModalFired,
    filterModalFired: (state) => state.filterModalFired,
    mapLayerStyle: (state) => state.mapLayerStyle,
    mapInitialized: (state) => state.mapInitialized,
    activeFilterOverlay: (state) => state.activeFilterOverlay,
    activePropertyView: (state) => state.activePropertyView,
    propertyStatus: (state) => state.filters.propertyStatus,
    viewedListings: (state) => state.viewedListings,
    searchSuggestions: (state) => {
        return state.searchSuggestions;
    },
    searchSuggestionsLoading: (state) => state.searchSuggestionsLoading,
    interstitialState: (state) => state.interstitialState,
    agentSearchSuggestionsLoading: (state) => state.agentSearchSuggestionsLoading,
    poiSearchSuggestionsLoading: (state) => state.poiSearchSuggestionsLoading,
    agentSearchSuggestions: (state) => state.agentSearchSuggestions,
    poiSearchSuggestions: (state) => state.poiSearchSuggestions,
    publicRecordsSearchSuggestions: (state) => state.publicRecordsSearchSuggestions,
    publicRecordsSearchSuggestionsLoading: (state) => state.publicRecordsSearchSuggestionsLoading,
    actionLoadingStatus: (state) => state.actionLoadingStatus,
    ads: (state) => state.ads,
    defaultVanities: (state) => state.defaultVanities,
    searchVanities: (state) => state.searchVanities,
    currentSearchVanity: (state) => state.currentSearchVanity
};
