// import axios from 'axios/lib/axios';

export const formRequest = async (payload) => {
    try {
        let res = await axios.post("forms/form-request", payload);
        return res.data;
    } catch (e) {
      throw e
    }
};

export const validateAddressReq = async (payload) => {
    try {
        let res = await axios.post('/geocoding/validate?address=' + payload);
        return res.data;
    } catch (e) {
      throw e
    }
};

export const avmUrlRequest = async (avmURL) => {
    try {
        let res = await  axios({
            url: avmURL,
            baseURL: '/',
            params: { 'return-json': true }
        });
        return res.data;
    } catch (e) {
      throw e
    }
};
