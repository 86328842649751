export const login = async (credentials) => {
    try {
        let res = await axios.post('login', credentials, { withCredentials: true });
        return res.data;
    } catch (e) {
        throw e;
    }
};
export const register = async (credentials) => {
    try {
        let res = await axios.post('register', credentials, { withCredentials: true });
        return res.data;
    } catch (e) {
        throw e;
    }
};
export const progressiveRegistration = async (credentials) => {
    try {
        let res = await axios.post('register2', credentials, { withCredentials: true });
        return res.data;
    } catch (e) {
        throw e;
    }
};
export const passwordReset = async (email) => {
    try {
        let res = await axios.post('passwordReset', { email: email }, { withCredentials: true });
        return res.data;
    } catch (e) {
        throw e;
    }
};
export const updateUser = async (payload) => {
    try {
        let res = await axios.post('user/update', payload);
        return res.data;
    } catch (e) {
        throw e;
    }
};
// probably can merge to the above function
export const progressiveUpdateUser = async (payload, token) => {
    try {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        let res = await axios.post('user/update', payload, config);
        return res.data;
    } catch (e) {
        throw e;
    }
};

export const fetchSavedProperties = async (payload) => {
    const { skip = 1, take = 5 } = payload || {};
    try {
        let res = await axios.get('user/all-saved-properties?skip=' + skip + '&take=' + take);
        return res.data;
    } catch (e) {
        throw e;
    }
};

export const fetchSavedSearches = async (payload) => {
    const { skip = 1, take = 5 } = payload || {};
    try {
        let res = await axios.get('user/all-saved-searches?skip=' + skip + '&take=' + take);
        return res.data;
    } catch (e) {
        throw e;
    }
};
export const fetchViewedProperties = async (payload) => {
    const { skip = 1, take = 5 } = payload || {};
    try {
        let res = await axios.get('user/all-viewed-properties?skip=' + skip + '&take=' + take);
        return res.data;
    } catch (e) {
        throw e;
    }
};
export const contactAgent = async (payload) => {
    try {
        let res = await axios.post('forms/form-request', payload);
        return res.data;
    } catch (e) {
        throw e;
    }
};

export const storeSession = async (payload) => {
    try {
        let res = await axios.post('store-session', payload);
        return res.data;
    } catch (e) {
        throw e;
    }
};

// store user activity with rify
export const storeActivity = async (payload) => {
    try {
        let res = await axios.post('rify', payload);
        return res.data;
    } catch (e) {
        throw e;
    }
};

// favorite a property
export const favoriteProperty = async (propertyId, external) => {
    try {
        let url = external ? '/user/saved-properties-external/' : '/user/saved-properties/';
        let res = await axios.post(url + propertyId);
        return res.data;
    } catch (e) {
        throw e;
    }
};

export const unfavoriteProperty = async (propertyId, external) => {
    try {
        let url = external ? '/user/saved-properties-external/' : '/user/saved-properties/';
        let res = await axios.delete(url + propertyId);
        return res.data;
    } catch (e) {
        throw e;
    }
};
export const deleteSavedSearch = async (searchID) => {
    try {
        let param = { data: { id: searchID } };
        let res = await axios.delete('/user/saved-searches/', param);
        return res.data;
    } catch (e) {
        throw e;
    }
};
export const postSaveSearch = async (payload) => {
    try {
        let res = await axios.post('/user/saved-searches/', payload);
        return res.data;
    } catch (e) {
        throw e;
    }
};

export const userPropertyEligibleCount = async (payload) => {
    try {
        let res = await axios.post('/dpr/eligible', payload);
        return res.data;
    } catch (e) {
        throw e;
    }
};

export const fetchIPLocation = async () => {
    try {
        let res = await axios.get('/geoip/getCity');
        if (res.data.data.location.accuracy_radius <= 100) {
            return res.data.data;
        }
        return -1;
    } catch {
        return -1;
    }
};
