export default {
	data() {
		return {
			loopInterval: 200, 	// in ms
			offset: 85,			// in px
			scrolling: false,
			scrollAnchors: {},
			scrollAnchorSelector: null,
			scrollTargets: {},
			scrollTargetSelector: null,
		}
	},
	methods: {
		initializeScrollTargets(scrollAnchorSelector, scrollTargetSelector, offset = 120, loopInterval = 100, selector = window) {
			this.offset = offset;
			this.scrollAnchorSelector = scrollAnchorSelector;
			this.scrollTargetSelector = scrollTargetSelector;
			this.loopInterval = loopInterval;

			// assign scroll anchors
			this.assignScrollAnchors();

			// assign scroll anchors on load
			this.assignScrollTargetPositions();

			// assign scroll target positions every 1.5 seconds to account for
			// changing page height when clicking into tabs or resizing
			// the screen.
			setInterval(() => {
				this.assignScrollTargetPositions();
			}, 1500);

			// Monitor scrolling on the window.
			selector.addEventListener('scroll', (event) => {
			    this.scrolling = true;
			});
            // Find all the scrollable divs and loop through the collectionof them





			// Process the scroll loop once on load.
			this.scrollLoop(selector);

			// use an interval to not overload the scroll
			// functionality and cause performance issues.
			setInterval(() => {
				// if the window is currently scrolling we'll run the scroll loop.
			    if (this.scrolling) {
			        this.scrolling = false;
					this.scrollLoop(selector);
				}
			}, this.loopInterval);
		},
		scrollLoop(selector) {
	        let lastTargetPosition = 0;
	        let windowPosition = window.scrollY;
			let minScrollValue = Object.values(this.scrollTargets).sort((prev, next) => prev.y - next.y)[0].y - this.offset;

            if(selector != window){
                windowPosition = selector.scrollTop;
            }

			// if the window hasn't hit the first scrollable element we'll return early.
	        if(windowPosition < minScrollValue) {
		        let currentActiveAnchors = document.querySelector(this.scrollAnchorSelector + '.active');
		        if(currentActiveAnchors) {
		        	currentActiveAnchors.classList.remove('active');
		        }
		        return ;
	        }

	        // cycle through all scroll targets to compare the current window position to
	        // the target's top
			for (let [id, target] of Object.entries(this.scrollTargets)) {
				// if the window is passed the target let's set active states.
	        	if(windowPosition >= target.y - this.offset) {


	        		let targetElement = document.querySelector(this.scrollAnchorSelector + '[href="' + this.scrollAnchors[id] + '"]');
	        		let currentActiveAnchors = document.querySelector(this.scrollAnchorSelector + '.active');
			        // remove active status for anchors
			        if(currentActiveAnchors) {
			        	currentActiveAnchors.classList.remove('active');
			        }
					if(targetElement){
			        	// add active status to slected anchor
	        			targetElement.classList.add('active');
					}
	        	}
			}
		},
		assignScrollAnchors() {
			// get all of the scroll anchor elements
			document.querySelectorAll(this.scrollAnchorSelector).forEach(target => {
				let id = target.getAttribute("href").replace('#', '');

				if(id) {
					this.scrollAnchors[id] = target.getAttribute("href");
				}
			});
		},
		assignScrollTargetPositions() {
		    let offsetScrollTop = window.pageYOffset || document.documentElement.scrollTop;

			// Cycle through all scroll targets and assign each an x and y value
			document.querySelectorAll(this.scrollTargetSelector).forEach(target => {
				let r = target.getBoundingClientRect();
				this.scrollTargets[target.id] = {x: r.left, y: r.top + offsetScrollTop};
			});
		},
		scrollToTarget(e) {
			e.preventDefault();
			// get the target ID from the link's href to a local anchor
			let targetId = e.currentTarget.getAttribute('href').replace('#', '');

			if(this.scrollTargets[targetId]) {

				window.scrollTo(0, this.scrollTargets[targetId].y - this.offset + 10);

			}
		}
	}
}
