import { Modal } from 'bootstrap';
let setupElements = (area, content, link, parent) => {
    area.classList.add('expandable-area');
    content.classList.add('expandable-content');
    content.innerHTML = parent.innerHTML;
    parent.innerHTML = '';
    area.appendChild(content);
    parent.appendChild(area);
    link.classList.add('toggle-expand');
    area.appendChild(link);
};

let toggleExpand = (content) => {
    content.classList.toggle('v-expanded');
    content.classList.toggle('blur', !content.classList.contains('v-expanded'));
};

let updateText = (link, binding, expanded) => {
    let show_more = binding?.value?.show_more || 'Show More';
    let show_less = binding?.value?.show_less || 'Show Less';
    let show_arrow = binding?.value?.show_arrow || true;
    let svgIconUp = ' <svg width="15px" fill="#0C6897" viewBox="0 0 32 32" aria-hidden="true"   focusable="false" role="img" ><path stroke="none" d="M29.41 8.59a2 2 0 00-2.83 0L16 19.17 5.41 8.59a2 2 0 00-2.83 2.83l12 12a2 2 0 002.82 0l12-12a2 2 0 00.01-2.83z"></path></svg> ';
    let svgIconDown = ' <svg style="transform: rotate(180deg);" width="15px" fill="#0C6897" viewBox="0 0 32 32" aria-hidden="true"     focusable="false" role="img"><path stroke="none" d="M29.41 8.59a2 2 0 00-2.83 0L16 19.17 5.41 8.59a2 2 0 00-2.83 2.83l12 12a2 2 0 002.82 0l12-12a2 2 0 00.01-2.83z"></path></svg> ';
    if (show_arrow) {
        link.innerHTML = expanded ? svgIconDown : svgIconUp;
    }

    link.innerHTML += expanded ? show_less : show_more;
};

let calculateGivenHeight = (binding) => {
    let givenHeight = 80;
    if (binding.arg) {
        givenHeight = parseInt(binding.arg);
    }
    if (binding.value && binding.value.height) {
        givenHeight = parseInt(binding.value.height);
    }
    return givenHeight;
};

let updateHeight = (content, link, binding) => {
    let givenHeight = calculateGivenHeight(binding);
    let currentHeight = content.scrollHeight;

    if (currentHeight > givenHeight) {
        content.style.maxHeight = `${givenHeight}px`;
        content.classList.add('v-blur');
        link.style.display = 'block';
    } else {
        content.style.maxHeight = 'none';
        content.classList.add('v-expanded');
        link.style.display = 'none';
    }
};

let createAndShowModal = (content, binding) => {
    let title = binding.value?.modal_title || '';
    let customClass = binding.value?.modal_class || '';
    let modalType = binding.value?.modal_type || 'default';
    let modal = document.createElement('div');
    modal.classList.add('modal');
    modal.setAttribute('tabindex', '-1');
    modal.setAttribute('role', 'dialog');
    let closeButton = `<button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn btn-close"></button>`;
    let modalHeader = `<div class="modal-header">
                            <h3 class="modal-title">${title}</h3>
                            ${closeButton}
                        </div>`;

    modal.innerHTML = `
        <div class="modal-dialog modal-dialog-centered p-0 modal-fullscreen-sm-down ${customClass}" role="document">
            <div class="modal-content">
                ${modalType != 'review' ? modalHeader : ''}
                <div class="modal-body position-relative">
                    ${modalType == 'review' ? closeButton : ''}
                <div id="review_body" class="lh-relaxed" style="font-size:.9rem">${content.innerHTML}</div>
                 </div>
            </div>
        </div>
    `;
    document.body.appendChild(modal);
    // $(modal).modal('show');
    let modalInstance = new Modal(modal);
    modalInstance.show();
};

export default {
    inserted(el, binding, vnode) {
        const expandableArea = document.createElement('div');
        const expandableContent = document.createElement('div');
        const expandLink = document.createElement('div');

        setupElements(expandableArea, expandableContent, expandLink, el);

        if (binding.value && binding.value.show_in_modal) {
            expandLink.addEventListener('click', () => {
                createAndShowModal(expandableContent, binding);
            });
        } else {
            expandLink.addEventListener('click', () => {
                toggleExpand(expandableContent);
                updateText(expandLink, binding, expandableContent.classList.contains('v-expanded'));
            });
        }

        updateHeight(expandableContent, expandLink, binding);
        updateText(expandLink, binding, expandableContent.classList.contains('v-expanded'));

        vnode.context.$watch(
            () => binding.value,
            () => {
                updateText(expandLink, binding, expandableContent.classList.contains('v-expanded'));
                updateHeight(expandableContent, expandLink, binding);
            },
            { deep: true }
        );
    },

    update(el, binding, vnode) {
        vnode.context.$nextTick(() => {
            const expandableContent = el.querySelector('.expandable-content');
            updateHeight(expandableContent, el.querySelector('.toggle-expand'), binding);
        });
    }
};
